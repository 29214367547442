import { createContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../lib/firebase'
import { signInWithPhoneNumber } from "firebase/auth";
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};


export const AuthContext = createContext({
  ...initialState,
  logInWithPhone: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [firebaseUser, loading, error] = useAuthState(auth);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const fetchClaims = async () => {
      setState({
        isAuthenticated: true,
        isInitialized: true,
        user: {
          id: firebaseUser.uid,
        }
      });
    }

    if(loading){
      setState({
        isAuthenticated: false,
        isInitialized: false,
        user: null
      });
    }else if (firebaseUser) {
      fetchClaims()
    }else{
      setState({
        isAuthenticated: false,
        isInitialized: true,
        user: null
      });
    }
  }, [firebaseUser, loading])



  const logInWithPhone = async (number, verifier) => {
    try{
      const res = await signInWithPhoneNumber(auth, number, verifier);
      return res;
    }catch (e) {
      //TODO: SENTRY
      console.log(e)
    }
  };

  const logout = async () => {
    await auth.signOut();
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        logInWithPhone,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
