import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import Header from "./header";
import { Toaster } from 'react-hot-toast'

export default function Layout({ children }) {
  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
      <Wallet_modal />
      <BidsModal />
      <Toaster position={'bottom-center'}/>
    </div>
  );
}
