import "../styles/globals.css";
import 'react-phone-number-input/style.css'
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef } from "react";
import { AuthContext, AuthProvider } from '../context/firebase-context'
import { ManagerContextProvider } from '../hooks/useManager'
import { UserVoteContextProvider } from '../hooks/useUserVotes'

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  const isHomePage = router.pathname === '/';

  return (
    <>
    {isHomePage && <Meta title="Burger Fest 2024" />}

      <Provider store={store}>
        <ManagerContextProvider>
          <AuthProvider>
            <UserVoteContextProvider>
              <ThemeProvider  storageKey="app-theme" themes={['light', 'dark']} enableSystem={false} attribute="class">
              <MetaMaskProvider>
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                  {pid === "/login" ? (
                    <Component {...pageProps} />
                  ) : (
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  )}
                </UserContext.Provider>
              </MetaMaskProvider>
            </ThemeProvider>
            </UserVoteContextProvider>
          </AuthProvider>
        </ManagerContextProvider>
      </Provider>
    </>
  );
}

export default MyApp;
