import {getApp, getApps, initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator  } from 'firebase/functions'
import { getFirestore, connectFirestoreEmulator, collection, doc } from 'firebase/firestore'
import { getAuth, RecaptchaVerifier } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
};

function createFirebaseApp(config) {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
}

const firebaseApp = createFirebaseApp(firebaseConfig);

export const func = getFunctions(firebaseApp);
export const auth = getAuth(firebaseApp);
auth.languageCode = "es"
export const firestore = getFirestore(firebaseApp);
export const fsCollection = (path) => {
  return collection(firestore, path);
}
export const fsDoc = (path) => {
  return doc(firestore, path);
}
let analytics;
if (firebaseApp.name && typeof window !== 'undefined') {
  analytics = getAnalytics(firebaseApp);
}
export {analytics}
