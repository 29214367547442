import Head from "next/head";

const Meta = ({ title, keyword, desc, imageUrl, ogUrl}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png"/>
        <meta name="description" content={desc}/>
        <meta name="keyword" content={keyword}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={ogUrl}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={desc}/>
        <meta property="og:image" content={imageUrl}/>
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:url" content={ogUrl}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={desc}/>
        <meta name="twitter:image" content={imageUrl}/>
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: 'Burger Fest 2024',
  keyword:
    'burger fest',
  desc: "Burger Fest 2024",
  imageUrl: "/images/logo_2024.webp",
  ogUrl: 'https://www.burgerfest.com.pa'
};

export default Meta;
