import { createContext, useContext } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { fsDoc } from '../lib/firebase'

const d = { limitVoting: true, loginEnabled: false };
export const ManagerContext = createContext(d);

export const ManagerContextProvider = (props) => {

  const [data, loading, error] = useDocumentData(fsDoc('manager/--stats--'))

  const manager = data ?  data : d;
  if(!loading && error){
    console.log(error);
  }

  // Pass the value in Provider and return
  return <ManagerContext.Provider value={manager}>{props.children}</ManagerContext.Provider>
}

export const { Consumer: ManagerContextConsumer } = ManagerContext

export const useManager = () => useContext(ManagerContext)
