
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { createContext, useContext } from 'react'
import { useAuth } from './useAuth'
import { fsDoc } from '../lib/firebase'


const d = { lastVoted: undefined, votesLeft: undefined };
export const UserVoteContext = createContext(d);

export const UserVoteContextProvider = (props) => {

  const { user } = useAuth();
  const [data, loading, error] = useDocumentData(user && fsDoc(`users/${user?.id}`));

  const voteInfo = data ? { lastVoted: data?.lastVoted, votesLeft: data?.votesLeft } : d;
  if(!loading && error){
    // Sentry.captureException(error);
  }

  // Pass the value in Provider and return
  return <UserVoteContext.Provider value={voteInfo}>{props.children}</UserVoteContext.Provider>
}

export const { Consumer: UserVoteContextConsumer } = UserVoteContext

export const useUserVotes = () => useContext(UserVoteContext)
